<template>
  <el-dialog style="border-radious:5px;" :visible.sync='dialogTableVisible' title="配送订单骑手跟踪记录" class="records-dialog bg">
    <el-table :data='gridData' border height="500">
      <el-table-column prop='date' label='骑手等级'>
        <template slot-scope="scope">
          <span>{{scope.row.riderDeliverCount}}级骑手</span>
        </template>
      </el-table-column>
      <el-table-column prop='riderName' label='骑手姓名'></el-table-column>
      <el-table-column prop='riderPhone' label='联系电话'></el-table-column>
      <el-table-column prop='duration' label='配送时长'>
        <template slot-scope="scope">
          <span>{{scope.row.duration}}min</span>
        </template>
      </el-table-column>
      <el-table-column prop='realProfit' label='配送收益'></el-table-column>
      <el-table-column prop='arriveTime' label='存放时间'></el-table-column>
      <el-table-column prop='arriveAddress' label='存放地址'></el-table-column>
    </el-table>
  </el-dialog>

</template>

<script>
import { $sg_distributionRecord } from '@/api/ongoingOrder';

export default {
  data() {
    return {
      gridData: [],
      dialogTableVisible: false, // 显示弹窗
    };
  },
  methods: {
    openDialog(recordsId) {
      this.dialogTableVisible = true;
      $sg_distributionRecord({ orderId: recordsId }).then((res) => {
        this.gridData = res;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.records-dialog {
  .el-dialog {
    min-width: 1000px;
    border-radius: 10px;
    .el-dialog__header {
      background: #f8f7f7 !important;
      border-radius: 10px;
    }
    .el-dialog__body {
      .el-table th {
        background: #f8f7f7;
        color: black;
      }
    }
  }
}
</style>
