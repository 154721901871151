<template>
  <div class="orderDetailDialog" v-if="dialogVisible">
    <el-dialog title="订单详情" :visible.sync="dialogVisible" width="968.93px">
      <div v-loading="loading" element-loading-text="数据加载中...">
        <div class="flexbox">
          <div class="border-card order-detail-info">
            <div class="detail-info-main">
              <div class="order-li">订单号：{{ result.thirdOrderId }}</div>
              <div class="order-li">
                下单时间：{{ $formatDate(result.createTime, "yyyy-MM-dd HH:mm") }}
              </div>
              <div class="order-li">收货联系人：{{ result.contactName }}</div>
              <div class="order-li">收货电话：{{ result.contactTel }}</div>
              <div class="order-li">收货地址：{{ result.contactAddressDesensitization || result.contactAddress }}</div>
            </div>
            <div class="remarks" v-if="result.mark">
              备注:{{ result.mark }}
            </div>
          </div>
          <div class="border-card flex_1 steps-main-box">
            <div class="dialy-number">
              <el-image src="/img/order/mt_logo.png" class="mtLogo"></el-image>
              <span>#{{result.sequence}}</span>
            </div>
            <div class="img-box flexbox">
              <steps :stepList="stepList" :step="step" />
            </div>
          </div>
        </div>
        <div class="mt15 flexbox">
          <div style="width: 593px">
            <el-table :header-cell-style="{ borderColor: 'transparent' }" :cell-style="{ borderColor: 'transparent' }" stripe
              :data="result.orderDetail" border height="430" style="border-radius: 6px">
              <el-table-column label="商品名称" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="购买数量" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.quantity }}份</span>
                </template>
              </el-table-column>
              <el-table-column label="商品单价" align="center">
                <template slot-scope="scope">
                  <span>￥{{ scope.row.price }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="border-card finish-info">
            <div class="finish-part">
              <div class="bussiness-info">
                <div class="orderState">
                  <span class="order">{{orderStatus[result.status]}}</span>
                  <span class="refund" v-if="formateRefundState(result)">({{formateRefundState(result)}})</span>
                </div>
                <div class="bussiness-item bussiness-name">{{shopInfo.shopName}}</div>
                <div class="bussiness-item">{{shopInfo.shopContactTel}}</div>
                <div class="bussiness-item">
                  {{shopInfo.shopAddress}}
                </div>
              </div>
              <div class="order-li">商品金额：¥{{ totalMoeny.toFixed(2) || 0 }}</div>
              <div class="order-li">打包费用：¥{{ shippingFee.toFixed(2) || 0 }}</div>
              <div class="order-li">配送费用：¥{{ result.distribution }}</div>
              <div class="order-li">活动优惠：¥{{ result.preferentialFee }}</div>
              <div class="order-li">支付状态：{{result.payType===2?'在线支付-已支付':'到付'}}</div>
              <div class="order-li">顾客实付：¥{{ result.totalFee }}</div>
            </div>
          </div>
        </div>
        <div slot="footer"></div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import { $sg_getmtOrderDetail } from '@/api/ongoingOrder';
import steps from '@/components/common/Steps.vue';

export default {
  data() {
    return {
      dialogVisible: false,
      result: {},
      shopInfo: {}, // 商户信息
      orderStatus: ['', '待接单', '待发单', '骑手待接单', '待收货', '已完成', '已撤销'],
      step: 0,
      shippingFee: 0, // 打包费
      totalMoeny: 0, // 商品总价
      stepList: [
        {
          img: '/img/order/pay-money.png',
          activeImg: '/img/order/pay-money-active.png',
          name: '支付下单',
          step: 1,
        },
        {
          img: '/img/order/order.png',
          activeImg: '/img/order/order-active.png',
          name: '商户接单',
          step: 2,
        },
        {
          img: '/img/order/take-out-foods.png',
          activeImg: '/img/order/take-out-foods-active.png',
          name: '配送接单',
          step: 3,
        },
        {
          img: '/img/order/finish.png',
          activeImg: '/img/order/finish-active.png',
          name: '完成',
          step: 4,
        },
      ],
      loading: false,
    };
  },
  components: {
    steps,
  },
  methods: {
    // 打开弹窗
    open(thirdOrderId, shopInfo) {
      this.result = {};
      this.step = 0;
      this.shippingFee = 0;
      this.totalMoeny = 0;
      this.loading = true;
      this.dialogVisible = true;
      // ['', '待接单', '待发单', '骑手待接单', '待收货', '已完成', '已撤销'],
      $sg_getmtOrderDetail({ thirdOrderId }).then((res) => {
        const detailInfo = res;
        const stepList = [0, 1, 2, 2, 3, 4, 0];
        detailInfo.orderDetail = JSON.parse(res.orderDetail);
        detailInfo.orderDetail.forEach((item) => {
          this.shippingFee += item.box_price;
          this.totalMoeny += item.quantity * item.price;
        });
        this.shopInfo = shopInfo;
        this.step = stepList[res.status] ? stepList[res.status] : 0;
        this.result = detailInfo;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 0-不需要退款 1-发起退款，2-确认退款，3-驳回退款，4-用户取消退款申请，5-取消退款申诉
    formateRefundState({ refundStatus, status }) {
      const userRefundState = ['', '发起退款', '确认退款', '驳回退款', '用户取消退款申请', '取消退款申诉'];
      if (status === 5) {
        return '';
      } else if (userRefundState[refundStatus]) {
        return userRefundState[refundStatus];
      }
      return '';
    },
  },
};
</script>
<style lang="scss">
@import "@/style/var.scss";
.orderDetailDialog {
  .el-dialog {
    border-radius: 6px;
    .el-dialog__header {
      padding: 10px 35px;
      border-bottom: 1px solid #ececec;
      background: #f8f7f7;
      border-radius: 6px 6px 0 0;
      .el-dialog__title {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        color: #333333;
      }
      .el-dialog__headerbtn {
        top: 14px;
        right: 20px;
      }
    }
    .el-dialog__body {
      padding: 18px 35px 28px;
    }
    .el-dialog__footer {
      text-align: center;
      padding: 0;
      .btn-main {
        padding: 14px 0 24px;
        .el-button {
          min-width: 110px;
          height: 32px;
          &.el-button--default {
            border: 1px solid #ececec;
            color: #999999;
          }
          + .el-button {
            margin-left: 20px;
          }
        }
      }
      &:after {
        display: block;
        content: "";
        height: 20px;
        border-top: 1px solid #ececec;
        background: #f8f7f7;
        border-radius: 0 0 6px 6px;
      }
    }
  }
  .steps-main-box {
    padding: 18px 44px;
    box-sizing: border-box;
    .dialy-number {
      display: flex;
      align-items: center;
      font-size: 36px;
      font-weight: bold;
      line-height: 50px;
      color: $--theme-color;
      .mtLogo {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 14px;
      }
    }
    .img-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      .orderProgress {
        display: flex;
        height: 84px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .proImg {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
  .shop-img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    margin: 5px 0;
  }
  .finish-info {
    width: 282.25px;
    margin-left: 20px;
    box-sizing: border-box;
    height: 430px;
    overflow-y: auto;
    .finish-part {
      padding: 20px 14px 20px 20px;
      & + .finish-part {
        border-top: 1px dashed #e4e4e4;
      }
      .bussiness-info {
        padding-bottom: 18px;
        margin-bottom: 20px;
        box-sizing: border-box;
        border-bottom: 1px dashed #f0f0f0;
        .orderState {
          color: $--theme-color;
          .order {
            font-size: 20px;
            font-weight: bold;
          }
          .refund {
            font-size: 14px;
            margin-left: 12px;
          }
        }
        .bussiness-item {
          font-size: 12px;
          color: #666;
          margin-top: 8px;
        }
        .bussiness-name {
          margin-top: 13px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
  .order-detail-info {
    padding: 24px 12px 14px;
    width: 299px;
    margin-right: 13px;
    min-height: 239.41px;
    box-sizing: border-box;
    .detail-info-main {
      padding: 0 10px;
    }
    .remarks {
      background: #f5f5f5;
      border-radius: 5px;
      width: 100%;
      padding: 9px 13px;
      box-sizing: border-box;
      margin-top: 13px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #666666;
    }
  }
  .border-card {
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 6px;
    .order-li {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #666666;
      + .order-li {
        margin-top: 8px;
      }
      .tips {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #999999;
        margin-left: 14px;
      }
      .real-income {
        color: $--theme-color;
        font-size: 14px;
        line-height: 20px;
      }
      .income-state {
        color: #999999;
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }
}
</style>
