<template>
  <!-- 骑手管理 -->
  <el-card class="box-card">
    <!-- 查询条件 -->
    <el-form :inline="true" label-width="70" :model="filtrateInfo" class="rider-info-search">
      <el-form-item label="账号状态">
        <el-select size="medium" v-model="nowAccouteStatus" @change="(val)=>{changeSearch(val,1)}">
          <el-option :label="item.label" :value="item.id" v-for="item in accouteStatus" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="岗位">
        <el-select size="medium" v-model="filtrateInfo.professionType" @change="changeSearch">
          <el-option :label="item.label" :value="item.value" v-for="item in jobsType" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input size="medium" v-model="filtrateInfo.riderName" placeholder="请输入姓名" clearable @clear="changeSearch"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input size="medium" type="number" v-model="filtrateInfo.riderPhone" placeholder="请输入手机号" clearable @clear="changeSearch">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="filtrate($event)">筛选</el-button>
        <el-button size="medium" type="primary" @click="clearFiltrateInfo($event)">清空</el-button>
      </el-form-item>
    </el-form>
    <!-- 操作按钮 -->
    <div>
      <el-button size="medium" type="primary" @click="editRiderInfo(0)">添加骑手</el-button>
      <el-button size="medium" type="primary" @click="$router.push('riderposition')">查看骑手定位</el-button>
    </div>
    <!-- 数据表格 -->
    <el-table :data="tableData" border stripe class="k-table">
      <el-table-column class-name="rider-headerImg" prop="name1" label="头像" align="center" width="60">
        <template slot-scope="scope">
          <img style="width: 38px; height: 38px" :src="scope.row.imgUrl?scope.row.imgUrl:require('../../assets/img/rider/default.png')" />
        </template>
      </el-table-column>
      <el-table-column prop="riderName" label="姓名" />
      <el-table-column prop="riderPhone" label="电话" />
      <el-table-column prop="professionType" label="岗位" :formatter="formatterJob" />
      <el-table-column prop="totalOrderCount" label="累计接单(笔)" />
      <el-table-column prop="profit" label="累计收益" />
      <el-table-column prop="createDay" label="创建时间">
        <template slot-scope="scope">
          <span>{{scope.row.createDay || 0}}天</span>
        </template>
      </el-table-column>
      <el-table-column label="接单状态">
        <template slot-scope="scope">
          <span v-html="formatterRiderStatus(scope.row.workState)"></span>
        </template>
      </el-table-column>
      <el-table-column label="账号状态">
        <template slot-scope="scope">
          <span v-html="formatterRiderAcountType(scope.row.state)"></span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="120px">
        <template slot-scope="scope">
          <el-button type="text" @click="editRiderInfo(scope.row.id)">编辑</el-button>
          <el-button type="text" @click="riderDetail(scope.row.id)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination background :page-count="totalPages" :current-page.sync='filtrateInfo.pageCurrent' @current-change="filtrate">
    </el-pagination>
    <!-- 新增 编辑骑手 -->
    <addeditorrider ref="editorRider" @addEditorRider="addEditorRider" :riderId="nowRiderId" @clearRiderId="nowRiderId=0" />
    <setRider/>
  </el-card>
</template>

<script>
import setRider from '@/components/setRider/setRider.vue';
import addeditorrider from './addEditorRider.vue';
import { $sg_getRider, $sp_editorRider, $sp_addRider } from '../../api/rider';

export default {
  components: {
    addeditorrider,
    setRider,
  },
  data() {
    return {
      totalPages: 0, // 总页数
      filtrateInfo: { // 筛选条件
        pageCurrent: 1, // 分页参数
        pageSize: 10, // 分页参数
        professionType: undefined, // 岗位 1全职 2兼职
        riderName: '', // 骑手姓名
        riderPhone: '', // 骑手手机号
        state: undefined, // 账号状态 1启用 2禁用
        workState: undefined, // 骑手状态
      },
      nowAccouteStatus: 0, // 当前骑手状态
      // 账号状态
      accouteStatus: [
        { label: '全部', value: undefined, id: 0 },
        { label: '休息中', value: 1, id: 1 },
        { label: '接单中', value: 2, id: 2 },
        { label: '禁用', value: 2, id: 3 },
      ],
      // 岗位
      jobsType: [
        { label: '全部', value: undefined },
        { label: '全职', value: 1 },
        { label: '兼职', value: 2 },
      ],
      // 表格数据
      tableData: [],
      nowRiderId: 0, // 当前骑手
    };
  },
  mounted() {
    this.filtrate();
  },
  methods: {
    // 筛选
    filtrate(e) {
      this.btnBlur(e);
      $sg_getRider(this.filtrateInfo).then(({ pages, records }) => {
        this.totalPages = pages;
        this.tableData = records;
      });
    },
    // 下拉框change
    changeSearch(val, type) {
      this.filtrateInfo.pageCurrent = 1;
      if (type) {
        const values = this.accouteStatus[val].value;
        this.filtrateInfo.state = val === 3 ? values : undefined;
        this.filtrateInfo.workState = val !== 3 && values ? values : undefined;
      }
      this.filtrate();
    },
    // 清空筛选条件
    clearFiltrateInfo(e) {
      this.btnBlur(e);
      this.filtrateInfo = this.$options.data().filtrateInfo;
      this.nowAccouteStatus = 0;
      this.filtrate();
    },
    // 让button 失去焦点
    btnBlur(e) {
      if (e && e.type === 'click') {
        let target = e.target;
        if (target.nodeName === 'SPAN') {
          target = e.target.parentNode;
        }
        target.blur();
      }
    },
    // 新增 编辑骑手信息 打开弹框
    editRiderInfo(id) {
      this.nowRiderId = id;
      if (!id) {
        this.$refs.editorRider.openDialog();
      }
    },
    // 保存 1 新增 2 编辑
    addEditorRider(val) {
      const methods = [undefined, $sp_addRider, $sp_editorRider];
      methods[this.nowRiderId ? 2 : 1](val).then(() => {
        this.filtrate();
        this.$refs.editorRider.resetData(this.nowRiderId);
      }).catch((err) => {
        if (this.nowRiderId) {
          this.$message.error(err.msg);
        }
        this.$refs.editorRider.isSave = false;
      });
    },
    // 查看骑手详情
    riderDetail(id) {
      this.$router.push({ path: 'riderdetail', query: { id } });
    },
    // 格式化岗位 1 全职 2 兼职
    formatterJob({ professionType }) {
      const type = this.jobsType.find((item) => item.value === professionType);
      return type.label;
    },
    // 格式化接单状态 1休息中 2接单中 3配送中
    formatterRiderStatus(type) {
      if (!type) {
        return '';
      }
      const types = [
        undefined,
        { label: '休息中', color: '#666' },
        { label: '接单中', color: '#FA8828' },
        { label: '配送中', color: '#F22424' },
      ];
      return `<span style="color:${types[type].color};">${types[type].label}</span>`;
    },
    // 格式骑手账号状态  账号状态 1启用 2禁用
    formatterRiderAcountType(type) {
      if (!type) {
        return '';
      }
      const types = [
        undefined,
        { label: '启用中', color: '#FA8828' },
        { label: '禁用中', color: '#666' },
      ];
      return `<span style="color:${types[type].color};">${types[type].label}</span>`;
    },
  },
};
</script>

<style scoped lang="scss">
.box-card {
  border: none;
  font-size: 12px;
}
/deep/.rider-info-search {
  .el-form-item__content {
    width: 155px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
}
.k-table {
  margin: 30px 0;
}
/deep/.el-table_1_column_1.rider-headerImg {
  padding: 0 !important;
}
</style>
