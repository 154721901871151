import api from './api';
// 查询松鼠订单
export const $sp_getSSOrder = (params) => api.postNoMessage('/api/agent/biz/order/loadOrderListByCondition',
  params);
// 查询第三方订单
export const $sg_getMTOrder = (params) => api.get('/api/agent/biz/order/selectOrderList', params);
// 松鼠确认送达
// export const $sp_delivery = (params) => api.post('/order/v1/OrderEvent/setOrderArrived', params);
export const $sp_delivery = (params) => api.postNoMessage('/j/distribution/agent/order/management/complete', params);
// 松鼠撤销订单
export const $sp_cancelOrder = (params) => api.post('/order/v2/OrderEvent/cancelOrder', params);
// 美团撤销订单   
export const $sg_mtCancelOrder = (params) => api.post('/w/agentAdmin/cancelOrder', params);
// 美团订单详情
export const $sg_getmtOrderDetail = (params) => api.get('/api/agent/biz/order/selectOrderDetail', params);
// 获取松鼠订单详情
export const $sp_getssOrderDetail = (params) => api.postNoMessage('/api/agent/biz/order/getOrderDetails', params);
// 获取骑手当前位置
export const $sg_getRiderPort = (params) => api.get('/j/distribution/agent/order/management/queryDetailBySkOrderId', params);
// 再次配送
export const $sg_sendRelayOrder = (params) => api.post('/api/agent/biz/order/sendRelayOrder', params);
// 配送记录
export const $sg_distributionRecord = (params) => api.get('/j/distribution/agent/order/management/distributionRecord', params);
// 查询订单地址信息
export const $sg_etOrderAddress = (params) => api.get('/api/agent/biz/order/getOrderAddress', params);
// 转自配送
export const $sp_selfDelivery = (params) => api.post('/w/agentAdmin/selfDelivery', params);

