<template>
  <div v-if="model">
    <el-dialog class="k-setRider-dialog" :visible.sync="model" width="600px" :close-on-click-modal="false" :close-on-press-escape="false"
      :show-close="false" destroy-on-close>
      <div v-show="setRiderModel">
        <p class="setRider-title">你是否需要用自己的骑手,去配送第三方订单(美团等)。</p>
        <p class="setRider-mark">注：你需要有自己的骑手团队,设置配送规则,美团订单才能够同步到你的骑手(可在配送设置中进行修改)</p>
        <el-radio-group class="setRider-radio" v-model="radio">
          <el-radio :label="1">已有骑手开始配送美团订单</el-radio>
          <el-radio :label="0">暂时无骑手接美团配送订单</el-radio>
        </el-radio-group>
        <PrevReclick>
          <el-button type="primary" class="setRider-footer-btn" :loading="confirmSet" :disabled="confirmSet" @click="submit(0)">确定设置
          </el-button>
        </PrevReclick>
      </div>
      <div v-show="withoutModel">
        <p class="setRider-title">暂时不开通配送美团订单功能</p>
        <p class="without-mark ">注：可在配送设置中进行修改</p>
        <div class="without-footer-btn">
          <el-button type="info" class="without-btn" :loading="confirmSet" :disabled="confirmSet" @click="submit(1)">重新设置</el-button>
          <PrevReclick>
            <el-button type="primary" class="without-btn" :loading="confirmSet" :disabled="confirmSet" @click="submit(2)">确定设置</el-button>
          </PrevReclick>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>

<script>
import { $sg_selectMeituanSetting, $sp_addSetting, $sg_isBillingRules } from '@/api/setting';

export default {
  props: {
    // 调用组件的来源  rider 骑手管理 setting 配送设置
    source: {
      type: String,
      default: 'rider',
    },
  },
  data() {
    return {
      model: false,
      setRiderModel: false,
      withoutModel: false,
      radio: 0,
      confirmSet: false,
    };
  },
  created() {
    if (this.source === 'rider' || !sessionStorage.getItem('linkFormRider')) {
      $sg_selectMeituanSetting().then((d) => {
        if (!d || !d.state) {
          this.setModelStatus();
        }
      });
    } else {
      sessionStorage.removeItem('linkFormRider');
    }
  },
  methods: {
    submit(type) {
      this.setModelStatus(type);
    },
    setModelStatus(type = 1) {
      if ((!type && this.radio) || type === 2) {
        this.confirmSet = true;
        // 设置为暂不配送
        if (type === 2) {
          // 1.配送第三方订单 0.不配送第三方订单
          this.setRider(type);
        } else {
          // 骑手管理需判断是否设置完善 code 1000 配置完善
          $sg_isBillingRules().then((d) => {
            if (!d) {
              this.$emit('edit');
              this.model = false;
            } else if (d.code === 1000) {
              this.setRider(type);
            }
          }).catch((err) => {
            console.log(err, 'err');
            if (err.code === 1001) {
              if (this.source === 'rider') {
                sessionStorage.setItem('linkFormRider', 1);
                this.$router.push('/main/delivery/takeout');
              }
              this.model = false;
              this.modelSet(type);
            }
            this.confirmSet = false;
          });
        }
      } else {
        this.radio = 0;
        this.model = true;
        this.modelSet(type);
      }
    },
    modelSet(type) {
      this.setRiderModel = type === 1 || false;
      this.withoutModel = (!type && !this.radio) || false;
    },
    setRider(type) {
      $sp_addSetting({ val: this.radio }).then(() => {
        this.model = false;
        this.modelSet(type);
        this.$message({
          message: '设置成功',
          type: 'success',
        });
      }).catch((error) => {
        if (error.msg) {
          this.$message.error(error.msg);
        }
      }).finally(() => {
        this.confirmSet = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.setRider-title {
  font-size: 20px;
  font-weight: bold;
}
.setRider-mark,
.without-mark,
.setRider-title {
  text-align: center;
  box-sizing: border-box;
  padding: 0 30px;
}
.setRider-mark {
  margin: 20px 0;
}
.setRider-footer-btn {
  display: block;
  margin: 0 auto;
  width: 240px;
  height: 40px;
}
.setRider-radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  .el-radio {
    margin: 0 0 10px 0;
  }
}
.without-mark {
  margin: 20px 0 40px 0;
}
.without-footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  .without-btn {
    width: 200px;
    height: 40px;
  }
}
</style>
