<template>
  <div class="rider-detail">
    <el-card class="dflex rider-detail-topInfo">
      <!-- 骑手个人信息 -->
      <div class="dflex">
        <div class="rider-headerImg">
          <img class="wh" :src="riderDetailInfo.imgUrl?riderDetailInfo.imgUrl:require('../../assets/img/rider/default.png')">
        </div>
        <div class="rider-personInfo">
          <p>姓名：{{riderDetailInfo.riderName}}</p>
          <p>性别：{{riderDetailInfo.gender===1?'男':'女'}}</p>
          <p>手机号：{{riderDetailInfo.riderPhone}}</p>
          <p>紧急联系人手机号：{{riderDetailInfo.riderConcatPhone}}</p>
          <p>身份证号：{{riderDetailInfo.idCard}}</p>
        </div>
      </div>
      <!-- 收益信息 -->
      <ul class="dflex">
        <li class="fcc sy-type">
          <div>
            <p class="fc title">可提现收益(元)</p>
            <p class="moeny">¥{{riderDetailInfo.canWithdraws||0}}</p>
          </div>
          <p class="fc">
            <span class="icons"></span>&nbsp;账号状态：
            <span :style="{color:status(riderDetailInfo.state,'state',true)}">
              {{status(riderDetailInfo.state,'state')}}
            </span>
          </p>
        </li>
        <li class="fcc sy-type">
          <div class="withdrawal">
            <p class="fc title">已提现(元)</p>
            <p class="moeny">¥{{riderDetailInfo.withdraws||0}}</p>
          </div>
          <p class="fc"><span class="icons"></span>&nbsp;提现微信：
            <span :style="{color:status(riderDetailInfo.bindWechat?1:2,'bindWechat',true)}">
              {{status(riderDetailInfo.bindWechat?1:2,'bindWechat')}}
            </span>
          </p>
        </li>
        <li class="fcc sy-type">
          <div>
            <p class="fc title">累计收益(元)</p>
            <p class="moeny">¥{{riderDetailInfo.profitAmount||0}}</p>
          </div>
          <p class="fc"><span class="icons"></span>&nbsp;工作状态：
            <span :style="{color:status(riderDetailInfo.workState,'workState',true)}">
              {{status(riderDetailInfo.workState,'workState')}}
            </span>
          </p>
        </li>
      </ul>
    </el-card>
    <!-- 操作按钮 -->
    <el-card style="margin:15px 0;">
      <el-button size="medium" type="primary" @click="editPassword=true">修改密码</el-button>
      <el-button size="medium" type="primary" @click="editRiderInfo">编辑个人信息</el-button>
      <el-button size="medium" type="primary" :disabled="isDisabled" @click="disableOrEnable($event,riderDetailInfo.state)">
        {{riderDetailInfo.state===1?'禁用':'启用'}}账号
      </el-button>
      <el-button size="medium" type="primary" @click="bindingDialog=true">绑定提现微信</el-button>
    </el-card>
    <el-card>
      <!-- 数据统计 -->
      <p class="order-statistical">
        <span>今日配送：{{riderDetailInfo.dayDistributionCount || 0}}单</span>
        <span>本周配送：{{riderDetailInfo.weekDistributionCount || 0}}单</span>
        <span>本月配送：{{riderDetailInfo.monthDistributionCount || 0}}单</span>
        <span>累计配送：{{riderDetailInfo.allDistributionCount || 0}}单</span>
        <span>累计放弃配送：{{riderDetailInfo.allCancelOrderCount || 0}}单</span>
      </p>
      <!-- 数据表格 -->
      <el-table :data="tableData" border stripe class="k-table">
        <el-table-column prop="skOrderId" label="单号" width="130">
          <template slot-scope="scope">
            <p>配送单号：{{scope.row.skOrderId}}</p>
            <p>商户订单号：{{scope.row.shopOrderId}}</p>
          </template>
        </el-table-column>
        <el-table-column label="配送地址" min-width="370">
          <template slot-scope="scope">
            <div style="margin-bottom:20px;" class="flex">
              <p class="flex qIcon"><span></span><span class="wordOver">{{scope.row.merchantName}}-{{scope.row.merchantTel}}</span></p>
              <p class="q-orderAddress wordOver">{{scope.row.merchantAddress}}</p>
            </div>
            <div class="flex">
              <p class="flex sIcon"><span></span><span class="wordOver">{{scope.row.acceptName}}-{{scope.row.acceptTel}}</span></p>
              <p class="q-orderAddress wordOver">{{scope.row.acceptAddress}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="距离" align="center" width="90">
          <template slot-scope="scope">
            <span>{{scope.row.orderDistance || 0}}km</span>
          </template>
        </el-table-column>
        <el-table-column label="本单收益" align="center" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.state === 5 || scope.row.state === 6">{{scope.row.shareProfit || 0}}元</span>
            <span v-else>{{scope.row.profitMoney || 0}}元</span>
          </template>
        </el-table-column>
        <el-table-column label="送达时间" align="center" width="160">
          <template slot-scope="scope">
            <span>{{scope.row.realArriveTime || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="送达时长" align="center" width="120">
          <template slot-scope="scope">
            <span>{{scope.row.consumeTime?scope.row.consumeTime+'分钟':'—'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="80">
          <template slot-scope="scope">
            <span v-html="riderOrderType(scope.row.state)"></span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background :page-count="totalPages" :current-page.sync='pageCurrent' @current-change="getRiderLists">
      </el-pagination>
    </el-card>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" class="k-dialog" :visible.sync="editPassword" :close-on-click-modal="false" width="430px"
      :close-on-press-escape="false" center @closed="resetForm">
      <el-form class="editPass" :model="password" label-suffix=':' label-width="100px">
        <el-form-item label="登录手机号">
          <el-input placeholder="请输入登录手机号" type="number" v-model="password.riderPhone"></el-input>
        </el-form-item>
        <el-form-item label="登录密码">
          <el-input placeholder="请输入登录密码" v-model="password.password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input placeholder="再次输入登录密码" show-password v-model="password.repeatPassword"></el-input>
        </el-form-item>
        <el-form-item class="password-btns">
          <el-button @click="resetForm">取消</el-button>
          <PrevReclick>
            <el-button style="margin-left:20px;" type="primary" @click="submitForm">提交</el-button>
          </PrevReclick>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <!-- 编辑骑手 -->
    <addeditorrider ref="editorRider" :riderId="nowRiderId" @addEditorRider="addEditorRider" @clearRiderId="nowRiderId=0" />
    <!-- 微信提现 -->
    <el-dialog title="绑定微信提现" class="k-dialog" :visible.sync="bindingDialog" :close-on-click-modal="false" width="430px"
      :close-on-press-escape="false" center>
      <div class="fc binding-content">
        <div class="qrCode">
        </div>
        <p class="bingTishi">骑手提现微信进行扫码绑定</p>
        <div>
          <el-button @click="bindingDialog=false">取消</el-button>
          <PrevReclick>
            <el-button style="margin-left:20px;" type="primary">确认</el-button>
          </PrevReclick>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import addeditorrider from './addEditorRider.vue';
import {
  $sg_riderInfo, $sp_editorRiderStatus,
  $sp_editorPassword, $sg_riderOrderLists, $sp_editorRider,
} from '../../api/rider';

export default {
  components: {
    addeditorrider,
  },
  data() {
    return {
      editPassword: false, // 修改密码弹框
      bindingDialog: false, // 提现绑定
      totalPages: 1,
      pageCurrent: 1,
      tableData: [],
      password: {
        password: '', // 登录密码
        repeatPassword: '', // 确认登录密码
        riderId: '', // 骑手id
        riderPhone: '', // 骑手手机号
      },
      riderDetailInfo: {}, // 骑手详情
      nowRiderId: 0,
      riderId: 0,
      isDisabled: false, // 是否操作禁用启用
    };
  },
  created() {
    this.riderId = this.$route.query.id;
    this.getRiderInfo();
    this.getRiderLists();
  },
  methods: {
    // 禁用启用
    disableOrEnable(e, type) {
      let target = e.target;
      if (target.nodeName === 'SPAN') {
        target = e.target.parentNode;
      }
      target.blur();
      this.isDisabled = true;
      const hint = [
        undefined,
        { title: '禁用账号', btn: '确认禁用', content: '如果骑手有配送中的订单则无法被禁用，请先处理订单后再禁用账号。禁用后账号无法接单、提现等操作，是否确认禁用账号？' },
        { title: '启用账号', btn: '确认启用', content: '启用已被冻结的账号后，账号持有者可以继续使用接单、提现等操作。是否确认启用该账号？' },
      ];
      const data = {
        riderId: this.riderId,
        state: type === 1 ? 2 : 1,
      };
      this.$confirm(hint[type].content, hint[type].title, {
        confirmButtonText: hint[type].btn,
        cancelButtonText: '返回',
        dangerouslyUseHTMLString: true,
        customClass: 'k-messageBox-class warning',
      }).then(() => {
        $sp_editorRiderStatus(data).then(() => {
          this.getRiderInfo();
          this.isDisabled = false;
        }).catch(() => {
          this.isDisabled = false;
        });
      }).catch(() => {
        this.isDisabled = false;
      });
    },
    // 编辑骑手信息
    editRiderInfo() {
      this.nowRiderId = Number(this.riderId);
    },
    // 保存编辑
    addEditorRider(val) {
      $sp_editorRider(val).then(() => {
        this.getRiderInfo();
        this.$refs.editorRider.resetData(this.riderDetailInfo.id);
      }).catch(() => {
        this.$refs.editorRider.isSave = false;
      });
    },
    // 修改密码
    submitForm() {
      let tishi = '';
      this.password.riderId = this.riderId;
      const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      const reg_pwd = /^[0-9a-zA-Z]{6,18}$/;
      if (this.password.password !== this.password.repeatPassword) {
        tishi = '两次输入密码不一致';
      }
      if (!this.password.repeatPassword) {
        tishi = '请再次输入密码';
      }
      if (!reg_pwd.test(this.password.password)) {
        tishi = '请输入6-18密码(由数字或字母组成)';
      }
      if (!reg_tel.test(this.password.riderPhone)) {
        tishi = '请正确输入登录手机号';
      }
      if (tishi) {
        this.$message.error(tishi);
        return;
      }
      $sp_editorPassword(this.password).then(() => {
        this.resetForm();
        this.getRiderInfo();
        this.$alert('密码修改成功，请通知骑手重新登陆!', '修改成功', {
          confirmButtonText: '确定',
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
          customClass: 'k-messageBox-class success',
        }).then(() => { }).catch(() => { });
      });
    },
    // 取消操作
    resetForm() {
      this.editPassword = false;
      this.password = this.$options.data().password;
    },
    // 获取骑手信息
    getRiderInfo() {
      $sg_riderInfo({ id: this.riderId }).then((d) => {
        this.riderDetailInfo = d;
      });
    },
    // 获取骑手订单列表
    getRiderLists() {
      const data = {
        pageCurrent: this.pageCurrent,
        pageSize: 10,
        riderId: this.riderId,
      };
      $sg_riderOrderLists(data).then(({ pages, records }) => {
        this.totalPages = pages;
        this.tableData = records;
      });
    },
    status(val, type, isColor = false) {
      if (!val) {
        return '';
      }
      const allStatus = {
        state: [
          undefined,
          { label: '启用中', color: '#F22424' },
          { label: '禁用', color: '#666' },
        ],
        bindWechat: [
          undefined,
          { label: '已绑定', color: '#13CA7E' },
          { label: '未绑定', color: '#666' },
        ],
        workState: [
          undefined,
          { label: '休息中', color: '#666' },
          { label: '接单中', color: '#FA8828' },
          { label: '配送中', color: '#EF3F46' },
        ],
      };
      return isColor ? allStatus[type][val].color : allStatus[type][val].label;
    },
    riderOrderType(type) {
      const types = [undefined, '待接单', '已接单', '已到店', '配送中', '配送完成', '撤销订单'];
      return `<span style="color:${type === 4 ? '#EF3F46' : '#666'};">${types[type]}</span>`;
    },
  },
};
</script>

<style scoped lang="scss">
.wordOver {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/deep/.k-dialog {
  .el-dialog {
    border-radius: 6px;
    overflow: hidden;
    background: #ececec;
  }
  .el-dialog__header {
    color: #333;
    text-align: left;
    padding: 14px 20px 10px 35px;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 16px;
  }
  .el-dialog__body {
    background: #ffffff;
    border: 1px solid #ececec;
  }
  .el-dialog__footer {
    height: 20px;
    padding: 0;
  }
}
.editPass {
  /deep/.password-btns {
    margin: 57px 0 0 0;
    text-align: center;
    .el-button {
      width: 110px;
    }
    .el-form-item__content {
      margin: 0 !important;
    }
  }
  .el-form-item {
    padding-right: 10px;
  }
}

.binding-content {
  flex-direction: column;
  .bingTishi {
    font-size: 12px;
    color: #ef3f46;
    margin: 15px 0 30px 0;
  }
  .qrCode {
    width: 100px;
    height: 100px;
    background: red;
  }
  .el-button {
    width: 110px;
  }
}

.wh {
  width: 100%;
  height: 100%;
}
.dflex {
  display: flex;
}
.fc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fcc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.k-table {
  margin: 30px 0;
}
span {
  display: inline-block;
}
.rider-detail {
  .el-card {
    border: none;
  }
  .rider-detail-topInfo {
    color: #666;
    /deep/.el-card__body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .rider-headerImg {
        width: 120px;
        height: 120px;
      }
      .rider-personInfo {
        font-size: 14px;
        box-sizing: border-box;
        padding: 0 10px 0 21px;
        > p:not(:last-child) {
          margin-bottom: 10px;
        }
      }
      .sy-type {
        min-width: 223px;
        text-align: center;
        .icons {
          width: 20px;
          height: 20px;
        }
        .withdrawal {
          width: 100%;
          border-right: 1px solid #edf1f4;
          border-left: 1px solid #edf1f4;
        }
        .title {
          font-size: 16px;
          // span {
          //   width: 20px;
          //   height: 20px;
          //   margin-left: 8px;
          //   background: url("../../assets/img/rider/sm.png") no-repeat;
          //   background-size: 100% 100%;
          // }
        }
        > p span {
          margin-right: 8px;
        }
        .moeny {
          font-size: 32px;
          font-weight: bold;
          margin-top: 6px;
          color: #ef3f46;
        }
      }
      .sy-type:nth-child(1) > p {
        .icons {
          background: url("../../assets/img/rider/zt_sd.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .sy-type:nth-child(2) > p {
        .icons {
          background: url("../../assets/img/rider/dj.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .sy-type:nth-child(3) > p {
        .icons {
          background: url("../../assets/img/rider/zt.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}

.order-statistical {
  font-size: 14px;
  color: #666;
  span {
    margin-right: 60px;
  }
  > span:last-child {
    margin-right: 0;
  }
}
.q-orderAddress,
.qIcon,
.sIcon {
  box-sizing: border-box;
}
.qIcon,
.sIcon {
  width: 40%;
  padding-right: 10px;
  border-right: 1px solid #f0f0f0;
  > span:nth-child(1) {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  > span:nth-child(2) {
    width: calc(100% - 24px);
  }
}
.q-orderAddress {
  width: 60%;
  padding-left: 10px;
}
.qIcon > span:nth-child(1) {
  background: url("../../assets/img/rider/qj_q.png") no-repeat;
  background-size: 100% 100%;
}
.sIcon > span:nth-child(1) {
  background: url("../../assets/img/rider/qj_s.png") no-repeat;
  background-size: 100% 100%;
}
</style>
