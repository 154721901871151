import { render, staticRenderFns } from "./setRider.vue?vue&type=template&id=76c7f012&scoped=true&"
import script from "./setRider.vue?vue&type=script&lang=js&"
export * from "./setRider.vue?vue&type=script&lang=js&"
import style0 from "./setRider.vue?vue&type=style&index=0&id=76c7f012&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76c7f012",
  null
  
)

export default component.exports