<template>
  <!-- 再次配送弹窗 -->
  <div>
    <div class="again">
      <el-dialog title="再次发起配送" :visible.sync="centerDialogVisible" width="596px" center @close='closeDistribution'>
        <!-- <riderRelayExplain></riderRelayExplain> -->
        <div class="delivery-again">
          <div class="select-take-position">
            <p>选择取货地址</p>
            <el-button class="position-button" type="primary" @click="openLocationDialog">选择地址</el-button>
            <p class="word">{{fromAddress.address}}</p>
          </div>
          <div class="ipt-position">
            <p>输入取货地址</p>
            <el-input v-model="fromAddress.address" style="width: 75%; margin-right: 30px" maxlength="50" show-word-limit clearable
              @change="addAddressChange" placeholder="请输入取货详细地址"></el-input>
          </div>
          <div class="select-send-postion">
            <p>选择送货地址</p>
            <el-button type="primary" @click="openShopLocationDialog" class="position-button">选择地址</el-button>
            <p class="word">{{toAddress.address}}</p>
          </div>
          <div class="int-send-position">
            <p>输入送货地址</p>
            <el-input class="send-position" v-model='toAddress.address' maxlength="50" show-word-limit
              style="width: 75%; margin-right: 30px" clearable @change="shopAddressChange" placeholder="输入餐品当前送货的详细地址"></el-input>
          </div>
          <div class="userInfo">
            <p>用户信息</p>
            <p>{{toAddress.name}} {{toAddress.Phone}}</p>
          </div>
          <div class="sendPay">
            <p>配送费</p>
            <!-- <input type="text" placeholder="输入配送费" /> -->
            <el-input style="width: 75%; margin-right: 30px" clearable placeholder="输入配送费" v-model="distributionMoney"></el-input>
          </div>
          <p class="tips">
            注：此次补发配送费由代理商承担，系统直接扣除代理商账户余额
          </p>
          <div class="btns">
            <button class="confirm" @click='confirmeDeliveryAgain'>确认发单</button>
            <button class="cansole" @click="cansoleDeliveryAgain">取消</button>
          </div>

        </div>
      </el-dialog>
    </div>
    <!-- 获取取货定位弹窗 -->
    <el-dialog title="标记取货地址" :visible.sync="locationDialog" :close-on-click-modal="false" width="1221px">
      <div>
        <el-input v-model="fromAddress.address" style="width:465px;margin-bottom:15px" @change="searchAddress" placeholder="请输入门店详细地址">
        </el-input>
      </div>
      <div class="map-box">
        <div id="shopPointContainer" style="width:100%;height:549px;"></div>
      </div>
      <span slot="footer" class="dialog-footer flexbox flex-justify-center">
        <el-button type="primary" class="btn" @click="locationDialog = false">确认</el-button>
      </span>
    </el-dialog>
    <!-- 获取送货定位弹窗 -->
    <el-dialog title="标记送货地址" :visible.sync="shopLocationDialog" :close-on-click-modal="false" width="1221px">
      <div>
        <el-input v-model="toAddress.address" style="width:465px;margin-bottom:15px" @change="searchShopAddress" placeholder="请输入门店详细地址">
        </el-input>
      </div>
      <div class="map-box">
        <div id="shopPointContainer2" style="width:100%;height:549px;"></div>
      </div>
      <span slot="footer" class="dialog-footer flexbox flex-justify-center">
        <el-button type="primary" class="btn" @click="shopLocationDialog = false">确认</el-button>
      </span>
    </el-dialog>
    <!-- 余额弹窗 -->
    <!-- <el-dialog class="balance" title="小提示" :visible.sync="balanceDialogVisible" width="20%" center>
      <h3>您的账户余额已欠款{{ balance }}元，无法发起配送！</h3>
      <button class="balance-confirm" @click="balanceDialogVisible=false">确定</button>
    </el-dialog> -->
  </div>

</template>

<script>
import { AMapUtils } from '@base/utils';
import { $sg_sendRelayOrder } from '@/api/ongoingOrder';
// import { $sg_selectAgentMaxFund } from '@/api/delivery';
// import riderRelayExplain from '../../views/delivery/components/setting/riderRelayExplain.vue';

export default {
  components: {
    // riderRelayExplain,
  },
  data() {
    return {
      // position: '',
      // shopPosition: '',
      distributionMoney: '', // 配送费
      resendData: {},
      centerDialogVisible: false, // 再次配送弹窗
      locationDialog: false, // 取货地图弹窗
      shopLocationDialog: false, // 送货定位弹窗
      balanceDialogVisible: false, // 余额弹窗
      balance: '', // 余额
      fromAddress: {
        address: '',
        name: '',
        phone: '',
        position: '',
      },
      toAddress: {
        address: '',
        name: '',
        phone: '',
        position: [],
      },
      form: {
        shopLat: '',
        shopLng: '',
      },
      shopForm: {
        shopLat: '',
        shopLng: '',
      },
    };
  },
  props: {
    distributionAgain: {
      type: Boolean,
    },
    orderData: {
      type: Object,
    },
  },
  created() {
    this.toAddress = JSON.parse(JSON.stringify(this.orderData));
  },
  methods: {

    // 取消再次配送
    cansoleDeliveryAgain() {
      this.centerDialogVisible = false;
      this.distributionMoney = '';
      this.fromAddress = {
        address: '',
        name: '',
        phone: '',
        position: '',
      };
    },
    // 确定再次配送
    async confirmeDeliveryAgain() {
      if (this.form.shopLat === '' || this.shopForm.shopLat === '') {
        this.$message.error('未获取到详细定位，请重新选择地址');
        return;
      }
      if (this.distributionMoney === '') {
        this.$message.error('请填写配送费');
        return;
      }
      // 判断是否欠费50元
      // const res2 = await $sg_selectAgentMaxFund();
      // this.balance = res2.balance;
      // if (res2.balance < res2.maxDebts) {
      //   this.balanceDialogVisible = true;
      //   return;
      // }
      let orderId = '';
      let orderSource = '';
      if (this.orderData.activedTab === 0) {
        orderId = this.orderData.row.orderId;
        orderSource = 1;
      } else {
        orderId = this.orderData.row.orderNumber;
        orderSource = 2;
      }
      const params = {
        agentSid: '',
        distributionMoney: this.distributionMoney,
        fromAddress: {
          address: this.fromAddress.address,
          name: this.fromAddress.name,
          phone: this.fromAddress.phone,
          position: `${this.form.shopLat},${this.form.shopLng}`,
        },
        orderId,
        orderSource,
        toAddress: {
          address: this.toAddress.address,
          name: this.toAddress.name,
          phone: this.toAddress.phone,
          position: `${this.shopForm.shopLat},${this.shopForm.shopLng}`,
        },
        uniqueCode: '',
      };
      console.log(params);
      const res = await $sg_sendRelayOrder(params);
      // 重新拉取数据
      this.$parent.getList();
      if (res.code === 1001) console.log('测试成功');
      this.centerDialogVisible = false;
    },
    closeDistribution() {
      this.$emit('closeDistribution', this.centerDialogVisible);
      this.distributionMoney = '';
      this.fromAddress = {
        address: '',
        name: '',
        phone: '',
        position: '',
      };
    },
    // 收货详细地址改变
    addAddressChange() {
      this.form.shopLat = '';
      this.form.shopLng = '';
    },
    // 送货详细地址改变
    shopAddressChange() {
      this.shopForm.shopLat = '';
      this.shopForm.shopLng = '';
    },
    // 打开获取定位弹窗
    openLocationDialog() {
      const self = this;
      // if (!self.resendData.shopAddress) {
      //   self.$message.error('请输入取货详细地址');
      //   return;
      // }
      self.locationDialog = true;
      self.$nextTick(() => {
        self.initMap();
      });
      // this.shopPosition = this.resendData.shopAddress;
    },
    // 打开获取送货地址弹窗
    openShopLocationDialog() {
      const self = this;
      self.shopLocationDialog = true;
      self.$nextTick(() => {
        self.initMap2();
      });
      // this.position = this.resendData.address;
    },
    // 绘制地图
    initMap() {
      this.aMapUtils = new AMapUtils('shopPointContainer');
      this.aMapUtils.cityMap().then(() => {
        this.searchAddress();
        this.aMapUtils.mapAddEvent((map_lon, map_lat) => {
          this.aMapUtils.getAddress({ map_lon, map_lat }).then((res) => {
            this.form.shopLng = map_lat;
            this.form.shopLat = map_lon;
            this.$nextTick(() => {
              this.fromAddress.address = res;
              this.shopPosition = res;
              this.shopAddressList = [{
                location: {
                  lng: this.form.shopLng,
                  lat: this.form.shopLat,
                },
              }];
              this.resetMarker();
            });
          });
        });
      });
    },
    initMap2() {
      this.aMapUtils2 = new AMapUtils('shopPointContainer2');
      this.aMapUtils2.cityMap().then(() => {
        this.searchShopAddress();
        this.aMapUtils2.mapAddEvent((map_lon, map_lat) => {
          this.aMapUtils2.getAddress({ map_lon, map_lat }).then((res) => {
            this.shopForm.shopLng = map_lat;
            this.shopForm.shopLat = map_lon;
            this.$nextTick(() => {
              this.toAddress.address = res;
              this.position = res;
              this.addressList = [{
                location: {
                  lng: this.shopForm.shopLng,
                  lat: this.shopForm.shopLat,
                },
              }];
              this.resetMarker2();
            });
          });
        });
      });
    },
    // 搜索取货地址
    searchAddress() {
      this.aMapUtils.search(this.fromAddress.address, '全国').then((res) => {
        if (res.pois.length) {
          this.form.shopLng = res.pois[0].location.lat;
          this.form.shopLat = res.pois[0].location.lng;
        }
        this.shopAddressList = res.pois;
        this.resetMarker();
        console.log('搜索Form表单');
        console.log(this.form);
      });
    },
    // 搜索送货地址
    searchShopAddress() {
      this.aMapUtils2.search(this.toAddress.address).then((res) => {
        if (res.pois.length) {
          this.shopForm.shopLng = res.pois[0].location.lat;
          this.shopForm.shopLat = res.pois[0].location.lng;
        }
        this.addressList = res.pois;
        this.resetMarker2();
      });
    },
    // 重置标记
    resetMarker() {
      if (this.markList && this.markList.length > 0) {
        this.aMapUtils.map.remove(this.markList);
      }
      this.markList = [];
      this.shopAddressList.forEach((address) => {
        this.markList.push(this.aMapUtils.addMaker([address.location.lng, address.location.lat]));
      });
      this.aMapUtils.map.setFitView(this.markList);
      console.log('重置标记Form表单');
      console.log(this.form);
    },
    // 重置标记
    resetMarker2() {
      if (this.markList2 && this.markList2.length > 0) {
        this.aMapUtils2.map.remove(this.markList2);
      }
      this.markList2 = [];
      this.addressList.forEach((address) => {
        this.markList2.push(this.aMapUtils2.addMaker([address.location.lng, address.location.lat]));
      });
      this.aMapUtils2.map.setFitView(this.markList2);
    },

  },
  watch: {
    distributionAgain() {
      this.centerDialogVisible = this.distributionAgain;
    },
    orderData() {
      this.toAddress = JSON.parse(JSON.stringify(this.orderData.res.toAddress));
      this.fromAddress.name = this.orderData.row.shopName;
      this.fromAddress.phone = this.orderData.row.shopContactTel;
      this.shopPosition = '';
      this.toAddress.position = this.toAddress.position.split(',');
      this.shopForm.shopLat = this.toAddress.position[0];
      this.shopForm.shopLng = this.toAddress.position[1];
      this.position = this.toAddress.address;
      console.log(this.toAddress.position);
    },
  },
};
</script>

<style lang="scss" scoped>
.again {
  /deep/.el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      background: #f8f7f7 !important;
      border-radius: 10px;
    }
  }
  .delivery-again {
    .word {
      width: 65%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > div {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      > p:nth-child(1) {
        font-size: 16px;
        color: #444;
        margin-right: 5px;
      }
      input {
        width: 70%;
        height: 30px;
        border-radius: 10px;
        padding: 5px;
        border: 1px solid #cacaca;
        outline: none;
      }
    }
    .select-take-postion {
      p:nth-child(3) {
        font-size: 14px;
        color: #444;
      }
    }
    .userInfo {
      p:nth-child(1) {
        margin-left: 5%;
      }
    }
    .sendPay {
      margin-left: 10%;
    }
    .tips {
      color: #444;
      font-size: 14px;
      margin-bottom: 15px;
    }
    .btns {
      display: flex;
      justify-content: space-around;
      .confirm {
        background: #ed3e45;
        color: #fff;
        height: 50px;
        width: 30%;
        border: none;
        border-radius: 10px;
      }
      .cansole {
        border: none;
        background: #323542;
        color: #fff;
        height: 50px;
        width: 30%;
        border-radius: 10px;
      }
    }
  }
}
.balance-confirm {
  border-radius: 5px;
  width: 35%;
  height: 35px;
  background: #ed3e45;
  color: #fff;
  border: none;
  margin-left: 35%;
}
</style>
