import api from './api';
// 获取骑手列表
export const $sg_getRider = (params) => api.get('/j/distribution/agent/rider/management/page', params);
// 获取骑手详细信息
export const $sg_riderInfo = (params) => api.get('/j/distribution/agent/rider/management/detail', params);
// 修改密码 
export const $sp_editorPassword = (params) => api.post('/j/distribution/agent/rider/management/pwd/update', params);
// 修改骑手状态 
export const $sp_editorRiderStatus = (params) => api.post('/j/distribution/agent/rider/management/state/update', params);
// 修改骑手信息 
export const $sp_editorRider = (params) => api.postNoMessage('/j/distribution/agent/rider/management/update', params);
// 新增骑手 
export const $sp_addRider = (params) => api.post('/j/distribution/agent/rider/management/add', params);
// 根据骑手id 查找订单列表   
export const $sg_riderOrderLists = (params) => api.get('/j/distribution/agent/order/management/getOrderListByRiderId', params);
// 获取所有骑手位置 
export const $sg_queryRiderLocation = () => api.get('/j/distribution/agent/rider/management/queryAllRiderLocation');
// 获取当前骑手位置 
export const $sg_nowRiderLocation = (params) => api.get('/j/distribution/agent/rider/management/queryRiderLocation', params);
