/* eslint-disable guard-for-in */
<template>
  <el-dialog :title="`${riderId?'编辑':'新增'}个人信息`" class="k-edtorRiderdialog" :visible.sync="riderDialog" :close-on-click-modal="false"
    width="430px" :close-on-press-escape="false" center @closed="resetData">
    <el-form class="editRider" :model="riderInfo" label-suffix=':' label-width="100px">
      <el-form-item label="头像">
        <div class="headerImg">
          <img :src="riderInfo.imgUrl?riderInfo.imgUrl:require('../../assets/img/rider/default.png')">
        </div>
        <el-button type="danger" size="mini" round class="choose-headerImg" @click="chooseHeaderImg">选择图片</el-button>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input placeholder="请输入姓名" v-model="riderInfo.riderName"></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input placeholder="请输入联系电话" type="number" v-model="riderInfo.riderPhone"></el-input>
      </el-form-item>
      <el-form-item label="紧急联系电话">
        <el-input placeholder="请输入紧急联系电话" type="number" v-model="riderInfo.riderConcatPhone"></el-input>
      </el-form-item>
      <el-form-item label="身份证号">
        <el-input placeholder="请输入身份证号" v-model="riderInfo.idCard"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="riderInfo.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="岗位">
        <el-radio-group v-model="riderInfo.professionType">
          <el-radio :label="1">全职</el-radio>
          <el-radio :label="2">兼职</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="登录密码" v-if="!riderInfo.id">
        <el-input placeholder="请输入登录密码" v-model="riderInfo.password"></el-input>
      </el-form-item>
      <el-form-item label="再次确认" v-if="!riderInfo.id">
        <el-input placeholder="再次确认密码" show-password v-model="riderInfo.repeatPassword"></el-input>
      </el-form-item>
      <el-form-item class="rider-btns">
        <el-button @click="riderDialog = false">取消</el-button>
        <PrevReclick>
          <el-button style="margin-left:20px;" type="primary" @click="submitForm('riderForm')" :disabled="isSave" :loading="isSave">提交
          </el-button>
        </PrevReclick>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>

<script>
import ChooseFile from '@base/utils/file/ChooseFile';
import FileUploader from '@base/utils/file/FileUploader';
import { $sg_riderInfo } from '../../api/rider';

export default {
  data() {
    return {
      // 骑手信息
      riderInfo: {
        gender: 1, // 性别 1男 2女
        id: '', // 骑手id
        idCard: '', // 身份证号码
        password: '', // 登录密码
        professionType: 1, // 岗位 1全职 2兼职
        repeatPassword: '', // 确认登录密码
        riderConcatPhone: '', // 骑手紧急联系电话
        riderName: '', // 骑手名称
        riderPhone: '', // 骑手手机号
        imgUrl: '', // 头像
      },
      // 是否保存
      isSave: false,
      // 是否展示弹框
      riderDialog: false,
    };
  },
  props: {
    riderId: Number,
  },
  watch: {
    riderId(val) {
      if (val) {
        $sg_riderInfo({ id: val }).then((d) => {
          const keyArray = Object.keys(this.riderInfo);
          keyArray.forEach((item) => {
            this.riderInfo[item] = d[item];
          });
          this.openDialog();
        });
      }
    },
  },
  methods: {
    // 打开弹框
    openDialog() {
      this.riderDialog = true;
    },
    // 确认保存
    submitForm() {
      let tishi = '';
      const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      const reg_pwd = /^[0-9a-zA-Z]{6,18}$/;
      const rider_name = /^[\u4e00-\u9fa5]{1,5}$/;
      const idCard = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))([0-9]{3}([0-9]|x|X)|[0-9]{1})$/;
      if (!this.riderInfo.id) {
        if (this.riderInfo.repeatPassword !== this.riderInfo.password) {
          tishi = '两次输入密码不一致';
        }
        if (!this.riderInfo.repeatPassword) {
          tishi = '请再次输入密码';
        }
        if (!reg_pwd.test(this.riderInfo.password)) {
          tishi = '请输入6-18密码(由数字或字母组成)';
        }
      }
      if (!idCard.test(this.riderInfo.idCard)) {
        tishi = '请正确输入身份证';
      }
      if (!reg_tel.test(this.riderInfo.riderConcatPhone)) {
        tishi = '请正确输入紧急联系电话';
      }
      if (!reg_tel.test(this.riderInfo.riderPhone)) {
        tishi = '请正确输入联系电话';
      }
      if (!rider_name.test(this.riderInfo.riderName)) {
        tishi = '请正确输入姓名(只能包含汉字且长度不超过五位)';
      }
      if (tishi) {
        this.$message.error(tishi);
        return;
      }
      this.isSave = true;
      const rider = JSON.parse(JSON.stringify(this.riderInfo));
      this.$emit('addEditorRider', rider);
    },
    // 选择头像
    chooseHeaderImg() {
      const change = new ChooseFile();
      change.chooseImage(this.getFile.bind(this));
    },
    getFile(file) {
      const fileUploder = new FileUploader(file, { width: 120, height: 120, addLibrary: false });
      fileUploder.upload().then((url) => {
        this.riderInfo.imgUrl = url;
      }).catch((error) => {
        this.$message.error(error.message || '上传失败，请重新上传');
      });
    },
    // 重置数据 type 存在说明是编辑
    resetData(type) {
      this.riderDialog = false;
      this.isSave = false;
      this.riderInfo = this.$options.data().riderInfo;
      this.$emit('clearRiderId');
      if (type) {
        this.$alert('个人资料已经重新进行提交！', '修改成功', {
          confirmButtonText: '确定',
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
          customClass: 'k-messageBox-class success',
        }).then(() => { }).catch(() => { });
      }
    },
  },
};
</script>

<style  lang="scss">
.k-edtorRiderdialog {
  .el-dialog {
    border-radius: 6px;
    overflow: hidden;
    background: #ececec;
  }
  .el-dialog__header {
    color: #333;
    text-align: left;
    padding: 14px 20px 10px 35px;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 16px;
  }
  .el-dialog__body {
    background: #ffffff;
    border: 1px solid #ececec;
  }
  .el-dialog__footer {
    height: 20px;
    padding: 0;
  }
  .el-radio-group {
    width: 100%;
    .el-radio {
      width: 50%;
      margin: 0;
    }
    .el-radio:nth-child(1) {
      box-sizing: border-box;
      padding-left: 30px;
    }
  }
  .editRider {
    .rider-btns {
      margin: 57px 0 0 0;
      text-align: center;
      .el-button {
        width: 110px;
      }
      .el-form-item__content {
        margin: 0 !important;
      }
    }
    .el-form-item {
      padding-right: 10px;
      margin-bottom: 14px;
    }
  }
  .headerImg {
    width: 80px;
    height: 80px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
