<template>
  <div class="flexbox flex-align-center steps-page">
    <div v-for="(item,index) in stepList" :key="index" class="flexbox flex-align-center">
      <div class="flexbox flex-tb flex-align-center">
        <img :src="step>=item.step?item.activeImg:item.img" class="img" />
        <div class="name">{{item.name}}</div>
      </div>
      <img class="right-arrow" v-if="stepList.length!=index+1" src="/img/order/right-arrow.png" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    stepList: {
      type: Array,
    },
    step: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style scoped lang="scss">
.steps-page {
  .img {
    width: 60px;
    height: 60px;
  }
  .name {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #666666;
    margin-top: 12px;
  }
  .right-arrow {
    margin: 0 26px;
    width: 33.61px;
    height: 7.88px;
    padding-bottom:29px;
  }
}
</style>
