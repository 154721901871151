<template>
  <div class="orderDispatch">
    <!-- 搜索框区域 -->
    <div class="search-box">
      <div class="flexbox flex-align-center flex-wrap">
        <div class="mr60 mt20">
          <span class="name">订单状态</span>
          <el-select v-model="form.state" clearable class="inp">
            <el-option v-for="item in orderStateOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr60 mt20">
          <span class="name">流水号</span>
          <el-input v-model="form.sequence" class="inp" clearable placeholder="输入流水号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">商户名称</span>
          <el-input v-model="form.shopName" class="inp" clearable placeholder="输入商户名称"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">收货人名称</span>
          <el-input v-model="form.contactName" class="inp" clearable placeholder="输入收货人名称"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">收货人手机</span>
          <el-input v-model="form.contactTel" class="inp" clearable placeholder="输入手机号/尾号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">骑手名称</span>
          <el-input v-model="form.riderName" class="inp" clearable placeholder="输入姓名"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">骑手电话</span>
          <el-input v-model="form.riderPhone" class="inp" clearable placeholder="输入骑手手机号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">是否异常</span>
          <el-select v-model="form.isException" clearable class="inp">
            <el-option v-for="item in orderOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="mr60 mt20">
          <span class="name">单号搜索</span>
          <el-input v-model="form.orderNumber" style="width: 200px;" class="inp" clearable placeholder="输入订单/配送单号/票号"></el-input>
        </div>
        <div class="mr60 mt20">
          <span class="name">收发地址</span>
          <el-input v-model="form.address" class="inp" clearable placeholder="输入地址信息"></el-input>
        </div>
      </div>
      <div class="flexbox flex-justify-between">
        <div class="flexbox flex-align-center flex-wrap">
          <div class="mr60 mt20">
            <span class="name">订单日期</span>
            <span :class="mode == '1' ? 'mode mode-active' : 'mode'" @click="selectMode('1')">今日</span>
            <span :class="mode == '2' ? 'mode mode-active' : 'mode'" @click="selectMode('2')">昨日</span>
            <span :class="mode == '3' ? 'mode mode-active' : 'mode'" @click="selectMode('3')">近7日</span>
            <span :class="mode == '4' ? 'mode mode-active' : 'mode'" @click="selectMode('4')">近30日</span>
          </div>
          <el-date-picker v-model="time" class="time mt20" type="daterange" range-separator="至" value-format="yyyy-MM-dd"
            start-placeholder="选择开始日期" end-placeholder="选择结束日期" :clearable="false" :picker-options="dateRange" @change="timeChange">
          </el-date-picker>
          <el-button type="primary" class="btn search-btn" @click="search($event)">筛选</el-button>
          <el-button type="info" class="btn clear-btn" @click="clear">清空</el-button>
        </div>
      </div>
    </div>
    <div class="tabBtns">
      <span type="text" :class="activedTab === 0 ? 'actived-tab' : ''" @click="checkOutTab(0)">松鼠订单</span>
      <span type="text" :class="activedTab === 1 ? 'actived-tab' : ''" @click="checkOutTab(1)">美团订单</span>
    </div>
    <!-- table表格区域 -->
    <div class="table-box">
      <el-table :data="tableData" border style="width: 100%" v-loading="loading" element-loading-text="数据加载中...">
        <el-table-column label="单号">
          <template slot-scope="scope">
            <p style="margin-bottom: 20px" class="ticketNumber" v-if="scope.row.transCode">
              票号：{{ scope.row.transCode }}
            </p>
            <p style="margin-bottom: 20px" class="ticketNumber" v-if="scope.row.sequence">
              流水号：#{{ scope.row.sequence }}
            </p>
            <p style="margin-bottom: 20px" v-if="scope.row.deliverOrderNumber">
              配送单号：{{ scope.row.deliverOrderNumber }}
            </p>
            <p v-if="scope.row.orderNumber">
              订单号：{{ scope.row.orderNumber }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="订单信息" width="240px">
          <template slot-scope="scope">
            <div style="margin-bottom: 20px" v-if="scope.row.shopName">
              商家名称：{{ scope.row.shopName }}
            </div>
            <div style="margin-bottom: 20px" v-if="scope.row.courierName">
              骑手信息：{{ scope.row.courierName }} —
              {{ scope.row.courierPhone }}
            </div>
            <div style="margin-bottom: 20px">
              发单时间：{{ scope.row.orderCreateTime }}
            </div>
            <div>预计送达：{{ scope.row.sendPredictTime }}</div>
          </template>
        </el-table-column>
        <el-table-column label="收发地址" min-width="340px">
          <template slot-scope="scope">
            <div class="flexbox flex-align-center">
              <img src="/img/delivery/take.png" class="address-icon" alt="" />
              <div>
                <span>{{ scope.row.shopName }}-</span>
                <span>{{ scope.row.shopContactTel }}-</span>
                <span>{{ scope.row.shopAddress }}</span>
              </div>
            </div>
            <div class="flexbox flex-align-center mt20">
              <img src="/img/delivery/deliver.png" class="address-icon" alt="" />
              <div>
                <span>{{ scope.row.contactName }}-</span>
                <span>{{ scope.row.contactTel }}-</span>
                <span>{{ scope.row.address }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="配送公司">
          <template slot-scope="scope">
            <span>{{ psCompany[scope.row.openPlat] || "—" }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="配送方式">
          <template slot-scope="scope">
            <span>{{ psMethods[scope.row.distributionType] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="distance" align="center" label="距离">
          <template slot-scope="scope">
            <span>{{ scope.row.distance || 0 }}km</span>
          </template>
        </el-table-column>
        <el-table-column prop="exceptionMsg" align="center" label="异常信息">
          <template slot-scope="scope">
            <span class="info" v-if="scope.row.exceptionMsg">{{
              scope.row.exceptionMsg
            }}</span>
            <span class="info" v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="订单状态">
          <template slot-scope="scope">
            <span>{{
              (orderStateOptions[scope.row.state] &&
                orderStateOptions[scope.row.state].label) ||
              "—"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="openOrderDetailDialog(scope.row)">订单详情
            </el-button>
            <el-button type="text" @click="riderposition(scope.row)" v-if="btnsShow(2, scope.row)">
              骑手定位</el-button>
            <el-button type="text" class="info" @click="openDialog(1, scope.row)" v-if="btnsShow(3, scope.row)">确认送达</el-button>
            <el-button type="text" class="info" @click="openDialog(2, scope.row)" v-if="btnsShow(4, scope.row)">撤销订单</el-button>
            <el-button type="text" class="info" @click="openRecords(scope.row)"
              v-if="activedTab===0&&scope.row.state!==1&&scope.row.state!==2&&scope.row.state!==5">配送记录
            </el-button>
            <el-button type="text" class="info" @click="openRecords(scope.row)"
              v-if="activedTab===1&&scope.row.state!==1&&scope.row.state!==2&&scope.row.state!==3&&scope.row.state!==6">配送记录
            </el-button>
            <el-button type="text" class="info" @click="openDistribution(scope.row)"
              v-if="scope.row.state===4 && activedTab===0 &&getNum(scope.row.orderUpdateTime)" style="color:#EF3F46;">再次配送
            </el-button>
            <el-button type="text" class="info" @click="openDistribution(scope.row)"
              v-if="activedTab===1&&scope.row.state===5&&getNum(scope.row.orderUpdateTime)" style="color:#EF3F46;">再次配送
            </el-button>
            <el-button v-if="activedTab === 1 && (scope.row.state == 3 || scope.row.state == 4) && scope.row.distributionType != 2" 
                       type="text" class="info" @click="selfDistribution(scope.row)">转自配送</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- 确认送达 -->
    <el-dialog title="确认送达" class="k-dialog" :visible.sync="delivery" :close-on-click-modal="false" width="400px"
      :close-on-press-escape="false" center @closed="delivery = false">
      <div style="text-align: center">
        <p>确认送达后将更新订单状态为已完成</p>
      </div>
      <div class="password-btns">
        <el-button @click="delivery = false">取消</el-button>
        <PrevReclick>
          <el-button style="margin-left: 20px" type="primary" :loading="btnloading" :disabled="btnloading" @click="submitDelivery">确认
          </el-button>
        </PrevReclick>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <!-- 撤销订单 -->
    <el-dialog title="撤销订单" class="k-dialog" :visible.sync="cancelOrder" :close-on-click-modal="false" width="400px"
      :close-on-press-escape="false" center @closed="cancelOrder = false">
      <div style="text-align: center">
        <p>确认撤销此订单？</p>
      </div>
      <div class="password-btns">
        <el-button @click="cancelOrder = false">取消</el-button>
        <PrevReclick>
          <el-button style="margin-left: 20px" type="primary" :loading="btnloading" :disabled="btnloading" @click="cancelOrderSubmit">确认
          </el-button>
        </PrevReclick>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <!-- 配送详情弹窗 -->
    <deliveryRecordDialog ref="deliverDialog"></deliveryRecordDialog>
    <!-- 松鼠订单详情弹窗 -->
    <ssorderdetail ref="orderDetail"></ssorderdetail>
    <!-- 美团订单 -->
    <mtorder-detail ref="mtorderDetail" />
    <!-- 再次配送 -->
    <distributionAgain :distributionAgain='distributionAgainDialog' :orderData='orderData' @closeDistribution='closeDistribution'>
    </distributionAgain>
  </div>
</template>

<script>
import deliveryRecordDialog from '@/components/order/deliveryRecordDiaLog.vue';
import ssorderdetail from '@/components/order/ssOrderDetail.vue';
import mtorderDetail from '@/components/order/mtOrderDetail.vue';
import distributionAgain from '@/components/order/distributionAgain.vue';
import {
  $sp_getSSOrder,
  $sg_getMTOrder,
  $sg_mtCancelOrder,
  $sp_cancelOrder,
  $sg_getRiderPort,
  $sp_delivery,
  $sg_etOrderAddress,
  $sp_selfDelivery,
} from '@/api/ongoingOrder';

export default {
  data() {
    return {
      time: '',
      mode: '',
      ssOptions: [
        { value: '', label: '全部' },
        { value: '1', label: '待接单' },
        { value: '2', label: '骑手待接单' },
        { value: '3', label: '待收货' },
        { value: '4', label: '已完成' },
        { value: '5', label: '已撤销' },
      ],
      mtOptions: [
        { value: '', label: '全部' },
        { value: '1', label: '待接单' },
        { value: '2', label: '待发单' },
        { value: '3', label: '骑手待接单' },
        { value: '4', label: '待收货' },
        { value: '5', label: '已完成' },
        { value: '6', label: '已撤销' },
      ],
      orderStateOptions: [],
      orderOptions: [
        { value: '', label: '全部' },
        { value: '0', label: '正常订单' },
        { value: '1', label: '异常订单' },
      ],
      tableData: [],
      totalPage: 0,
      currentPage: 1,
      // 日期选择器可选择范围
      dateRange: {
        disabledDate(time) {
          // 限制最早只能从5月份开始查询,最大为当天
          return (
            new Date(time).getTime() < new Date('2021-04-30').getTime()
            || new Date(time).getTime() > new Date().getTime()
          );
        },
      },
      activedTab: 0, // 0松鼠订单 1美团订单
      psCompany: [
        '',
        '达达配送',
        '美团配送',
        '蜂鸟配送',
        '松鼠配送',
        '顺丰配送',
      ], // 配送公司 1达达，2美团，3蜂鸟，4松鼠
      psMethods: ['', '松鼠+三方配送', '商家自配送'], // 配送方式
      form: {
        state: '', // 订单状态，1-待接单，2-骑手待接单，3-待收货，4-配送完成，5-撤销 6-异常
        riderName: '', // 骑手名称/骑手电话
        isException: '', // 是否异常: 0:正常 ,1:异常
        sequence: '',
        shopName: '',
        contactName: '',
        contactTel: '',
        riderPhone: '',
        orderNumber: '',
        address: '',
      },
      delivery: false, // 确认送达
      cancelOrder: false, // 撤销订单
      nowOperationOrder: {},
      loading: false,
      btnloading: false,
      recordsDialog: false, // 配送记录
      distributionAgainDialog: false, // 再次配送
      recordsId: '', // 配送记录订单ID
      orderData: {},
      currentTime: '', // 当前时间时间戳
    };
  },
  components: {
    deliveryRecordDialog,
    ssorderdetail,
    mtorderDetail,
    distributionAgain,
  },
  created() {
    this.orderStateOptions = this.ssOptions;
    this.selectMode('4');
    this.search();
    this.currentTime = new Date().getTime();
    console.log('当前时间戳', this.currentTime);
  },
  methods: {
    // 打开配送记录
    openRecords(row) {
      this.$refs.deliverDialog.openDialog(row.deliverOrderNumber);
      console.log(row);
    },
    // 确认撤销订单  2001": "商家超时接单
    cancelOrderSubmit() {
      this.btnloading = true;
      const cancelMethods = [$sp_cancelOrder, $sg_mtCancelOrder];
      let parames = {};
      if (!this.activedTab) {
        parames = {
          order_id: this.nowOperationOrder.orderId,
        };
      } else {
        parames = {
          id: this.nowOperationOrder.orderNumber,
          reason: '商家超时接单',
          reasonCode: 2001,
        };
      }
      cancelMethods[this.activedTab](parames)
        .then(() => {
          this.getList();
          this.cancelOrder = false;
          this.btnloading = false;
        })
        .catch(() => {
          this.btnloading = false;
        });
    },
    // 订单确认送达
    submitDelivery() {
      this.btnloading = true;
      $sg_getRiderPort({
        skOrderId: this.nowOperationOrder.deliverOrderNumber,
      }).then((res) => {
        const params = {
          operationType: 2, // 操作类型 1骑手操作 2平台操作
          orderId: this.nowOperationOrder.deliverOrderNumber,
          orderStatus: 5,
          riderId: res.riderId,
        };
        $sp_delivery(params)
          .then(() => {
            this.getList();
            this.$message({
              message: '操作成功',
              type: 'success',
            });
            this.btnloading = false;
            this.delivery = false;
          })
          .catch((err) => {
            if (err.msg) {
              this.$message.error(err.msg);
            }
            this.btnloading = false;
          });
      });
    },
    // 确认送达弹框
    openDialog(type, row) {
      this.nowOperationOrder = row;
      switch (type) {
        case 1:
          this.delivery = true;
          break;
        case 2:
          this.cancelOrder = true;
          break;
        default:
          break;
      }
    },
    // state 订单状态，1-待接单，2-骑手待接单，3-待收货，4-配送完成，5-撤销 6-异常
    btnsShow(type, row) {
      const { state, openPlat } = row;
      let result = false;
      switch (type) {
        // 待收货状态下  且松鼠
        case 2: // 骑手定位
        case 3: // 确认送达
          if (
            ((!this.activedTab && state === 3)
              || (this.activedTab && state === 4))
            && openPlat === 4
          ) {
            result = true;
          }
          break;
        case 4: // 撤销订单  ( 松鼠订单 待接单，骑手待接单) (美团订单  待接单)
          if ((!this.activedTab && state === 2) || state === 1) {
            result = true;
          }
          break;
        default:
          break;
      }
      return result;
    },
    // 切换tab
    checkOutTab(tab) {
      this.selectMode('4');
      this.currentPage = 1;
      this.tableData = [];
      this.totalPage = 0;
      this.form.state = '';
      this.form.riderName = '';
      this.form.isException = '';
      this.orderStateOptions = !tab ? this.ssOptions : this.mtOptions;
      this.activedTab = tab;
      this.loading = true;
      this.getList();
    },
    search(e) {
      this.btnBlur(e);
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      const methods = [$sp_getSSOrder, $sg_getMTOrder];
      let params = {};
      this.loading = true;
      if (this.activedTab === 0) {
        params = {
          pageNum: this.currentPage,
          pageSize: 10,
          param: {
            ...this.form,
            orderBeginTime: this.time[0] ? `${this.time[0]} 00:00:00` : '',
            orderEndTime: this.time[1] ? `${this.time[1]} 23:59:59` : '',
          },
        };
      } else {
        params = {
          ...this.form,
          orderCloseTime: this.time[1] || '',
          orderStartTime: this.time[0] || '',
          pageCurrent: this.currentPage,
          pageSize: 10,
        };
      }
      methods[this.activedTab](params)
        .then((res) => {
          this.tableData = res.records || [];
          this.totalPage = res.pages;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    btnBlur(e) {
      if (e && e.type === 'click') {
        let target = e.target;
        if (target.nodeName === 'SPAN') {
          target = e.target.parentNode;
        }
        target.blur();
      }
    },
    // 选择订单日期
    selectMode(mode) {
      const date = new Date().getTime() / 1000;
      // 获取今天日期
      const today = this.$formatDate(date, 'yyyy-MM-dd');
      // 获取昨天日期
      const yesterday = this.$formatDate(date - 1 * 24 * 60 * 60, 'yyyy-MM-dd');
      // 获取7天前日期
      const beforeSevenDay = this.$formatDate(
        date - 7 * 24 * 60 * 60,
        'yyyy-MM-dd',
      );
      // 获取30天前日期
      const beforeThirtyDay = this.$formatDate(
        date - 30 * 24 * 60 * 60,
        'yyyy-MM-dd',
      );
      switch (mode) {
        // 今天
        case '1':
          this.time = [today, today];
          break;
        // 昨天
        case '2':
          this.time = [yesterday, yesterday];
          break;
        // 近7日
        case '3':
          this.time = [beforeSevenDay, today];
          break;
        // 近30日
        case '4':
          this.time = [beforeThirtyDay, today];
          break;
        default:
          break;
      }
      this.mode = mode;
    },
    // 日期选择器改变事件
    timeChange(val) {
      if (!val) {
        this.selectMode('1');
      } else {
        this.mode = '';
      }
    },
    // 清空
    clear() {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = '';
      });
      this.selectMode('4');
      this.currentPage = 1;
      this.tableData = [];
      this.totalPage = 0;
      this.activedTab = 0;
      this.getList();
    },
    // 打开订单详情弹窗
    openOrderDetailDialog(row) {
      if (this.activedTab === 0) {
        this.$refs.orderDetail.open(row.orderNumber);
      } else {
        const {
          orderNumber, shopAddress, shopContactTel, shopName,
        } = row;
        this.$refs.mtorderDetail.open(orderNumber, {
          shopAddress,
          shopContactTel,
          shopName,
        });
      }
    },
    // 查看骑手定位
    async riderposition(row) {
      let riderId = null;
      const info = await $sg_getRiderPort({
        skOrderId: row.deliverOrderNumber,
      });
      riderId = info.riderId;
      if (!riderId) {
        this.$message.error('等待骑手上传位置信息');
        return;
      }
      this.$router.push({
        path: '/main/delivery/riderposition',
        query: {
          riderId,
        },
      });
    },
    // 翻页
    pageChange() {
      this.getList();
    },
    // 再次配送
    closeDistribution(close) {
      this.distributionAgainDialog = close;
    },
    openDistribution(row) {
      console.log(row);
      let orderId = '';
      const orderSource = this.activedTab === 0 ? 1 : 2;
      if (this.activedTab === 0) {
        orderId = row.orderId;
      } else {
        orderId = row.orderNumber;
      }
      const params = {
        orderId,
        orderSource,
      };
      $sg_etOrderAddress(params).then((res) => {
        console.log(res);
        const data = { res, row, activedTab: this.activedTab };
        this.orderData = data;
      });
      this.distributionAgainDialog = true;
    },
    // 判断是否处于24小时内
    getNum(dt) {
      const date = new Date(dt).getTime();
      const oneDay = date + 86400000;
      if (this.currentTime >= date && this.currentTime <= oneDay) {
        return true;
      } else {
        return false;
      }
    },
    // 转自配送
    selfDistribution(row) {
      this.$confirm('确定要转自配送吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        const params = {
          id: row.orderNumber,
        };
        $sp_selfDelivery(params).then(() => {
          this.getList();
        });
      }).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import "@/style/var.scss";
.orderDispatch {
  width: 100%;
  background-color: #fff;
  color: #666;
  font-size: 14px;
  padding: 20px 15px 34px 25px;
  box-sizing: border-box;
  .name {
    margin-right: 10px;
  }
  .inp {
    width: 180px;
  }
  .mode {
    display: inline-block;
    padding: 6px 26px;
    color: #999;
    border: 1px solid #999;
    border-radius: 5px;
    margin-right: 14px;
    user-select: none;
    cursor: pointer;
  }
  & > .mode:last-child {
    margin-right: 46px;
  }
  .mode-active {
    color: $--theme-color;
    border: 1px solid $--theme-color;
  }
  .time {
    margin-right: 34px;
  }
  .btn {
    width: 80px;
    height: 32px;
    margin-top: 26px;
  }
  .search-btn {
    margin-right: 20px;
  }
  .clear-btn {
    background-color: #666;
    margin-left: 0px;
  }
  .table-box {
    width: 100%;
    margin-top: 20px;
    .el-table {
      // min-height: 580px;
      & > th {
        background-color: $--theme-color;
        color: #fff;
      }
    }
    .el-table__empty-block {
      min-height: 500px;
    }
    .ticketNumber {
      color: #ef3f46;
      font-weight: bold;
    }
    .info {
      color: #fa8828;
    }
    .address-icon {
      width: 23.85px;
      height: 23.85px;
      margin-right: 8px;
    }
  }
  .mr60 {
    margin-right: 60px;
  }
  .mt26 {
    margin-top: 26px;
  }
  .mt14 {
    margin-top: 14px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt46 {
    margin-top: 46px;
  }
}

.tabBtns {
  margin: 10px 0 14px 0;
  border-radius: 0;
  > span {
    display: inline-block;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    margin-right: 30px;
    font-size: 14px;
    color: #666;
  }
}
.actived-tab {
  border-bottom: 3px solid #ef3f46;
  color: #ef3f46 !important;
  font-weight: bold;
}
.k-dialog {
  .el-dialog {
    border-radius: 6px;
    overflow: hidden;
    background: #ececec;
  }
  .el-dialog__header {
    color: #333;
    // text-align: left;
    padding: 14px 20px 10px 35px;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 16px;
  }
  .el-dialog__body {
    background: #ffffff;
    border: 1px solid #ececec;
  }
  .el-dialog__footer {
    height: 20px;
    padding: 0;
  }
}
.password-btns {
  margin: 40px 0 0 0;
  text-align: center;
  .el-button {
    width: 110px;
  }
}
.cancel-order {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>
