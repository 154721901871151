<template>
  <el-card class="wh" style="border:none;">
    <el-page-header @back="goBack"></el-page-header>
    <div class="map-content" id="riderLngLat" v-loading="loading" element-loading-text="地图加载中">
    </div>
  </el-card>
</template>
<script>
import { AMapUtils } from '@base/utils';
import { $sg_queryRiderLocation, $sg_nowRiderLocation } from '../../api/rider';

export default {
  data() {
    return {
      loading: false,
      img: '/img/rider/defaultImg.png',
      riderStatus: [undefined, '休息中', '抢单中', '配送中'],
      nowRiderId: null, // 当前骑手id
    };
  },
  created() {
    this.loading = true;
    const riderId = this.$route.query.riderId;
    if (riderId) {
      this.nowRiderId = riderId;
    }
  },
  mounted() {
    this.createMap();
  },
  methods: {
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    // 地图初始化
    createMap() {
      this.amapUtils = new AMapUtils('riderLngLat');
      this.amapUtils.cityMap(15, {
        mapStyle: 'amap://styles/macaron',
        resizeEnable: true,
      }).then(() => {
        this.loading = false;
        this.addMarkers();
      });
    },
    // 获取标记点
    async addMarkers(type = false) {
      let portArray = [];
      if (this.nowRiderId) {
        const data = { riderId: this.nowRiderId };
        const rider = await $sg_nowRiderLocation(data);
        portArray.push(rider);
      } else {
        portArray = await $sg_queryRiderLocation();
      }
      if (portArray.length <= 0) {
        if (this.overlayGroup) {
          this.overlayGroup.removeOverlays(this.ports);
          this.overlayGroup2.removeOverlays(this.txtports);
          this.ports = []; // 头像标记集合
          this.txtports = []; // 文本标记集合
        }
      } else {
        const center = portArray[0];
        this.amapUtils.map.setCenter([center.longitude, center.latitude]);
        if (!type) {
          this.ports = []; // 头像标记集合
          this.txtports = []; // 文本标记集合
          portArray.forEach((item) => {
            this.ports.push(this.creartMarker(item, 1));
            this.txtports.push(this.creartMarker(item, 2));
          });
          this.overlayGroup = new window.AMap.OverlayGroup(this.ports);
          this.overlayGroup2 = new window.AMap.OverlayGroup(this.txtports);
          this.amapUtils.map.add(this.overlayGroup);
          this.amapUtils.map.add(this.overlayGroup2);
        } else {
          // 当前坐标点是否在新的坐标集合中 不存在删除 存在修改位置
          this.ports.forEach((item, index) => {
            const newIndex = portArray.findIndex((newItem) => newItem.riderId === item.w.id);
            if (newIndex > -1) { // 旧的点 在新的点集合中存在 就修改
              const nowPort = portArray[newIndex];
              this.ports[index].setPosition([nowPort.longitude, nowPort.latitude]);
              this.txtports[index].setPosition([nowPort.longitude, nowPort.latitude]);
              this.txtports[index].setLabel({
                content: `<div class="map-dialog">
                          <p class="rider-type">${nowPort.riderName}正在${this.riderStatus[nowPort.riderState]}...</p>
                          <p style="margin:2px 0;">${nowPort.riderPhone}</p>
                          <p>定位更新: ${this.formattingTime(nowPort.positioningUpdateTime)}</p>
                        </div>`, // 设置文本标注内容
              });
            } else { // 旧的点在新的点集合中不存在 就删除该点
              this.overlayGroup.removeOverlay(item);
              this.overlayGroup2.removeOverlay(this.txtports[index]);
              this.ports.splice(index, 1);
              this.txtports.splice(index, 1);
            }
          });
          portArray.forEach((portArrayItem) => { // 新的点集合中是否存在 旧集合中不存在的点  如有旧集合就添加
            const newIndex = this.ports.findIndex((portsItem) => portsItem.w.id === portArrayItem.riderId);
            if (newIndex < 0) {
              const newport = this.creartMarker(portArrayItem, 1);
              const newtxtport = this.creartMarker(portArrayItem, 2);
              this.ports.push(newport);
              this.txtports.push(newtxtport);
              this.overlayGroup.addOverlay(newport);
              this.overlayGroup2.addOverlay(newtxtport);
            }
          });
        }
      }
      this.riderTimeout = setTimeout(() => {
        this.addMarkers(true);
      }, 30000);
    },
    // 标记点 1 头像 2 文本
    creartMarker(item, type = 1) {
      if (type === 1) {
        return new window.AMap.Marker({
          content: `<div class="map-marker-diy">
                          <img src="${item.imgUrl ? item.imgUrl : this.img}">
                        </div>`, // 自定义点标记覆盖物内容
          position: [item.longitude, item.latitude],
          id: item.riderId,
          offset: new window.AMap.Pixel(-24, -47), // 相对于基点的偏移位置
        });
      } else {
        return new window.AMap.Marker({
          position: [item.longitude, item.latitude],
          topWhenClick: true,
          content: '<div></div>',
          zIndex: 200,
          id: item.riderId,
          label: {
            content: `<div class="map-dialog">
                            <p class="rider-type">${item.riderName}正在${this.riderStatus[item.riderState]}...</p>
                            <p style="margin:2px 0;">${item.riderPhone}</p>
                            <p>定位更新: ${this.formattingTime(item.positioningUpdateTime)}</p>
                          </div>`,
            offset: new AMap.Pixel(40, -30),
          },
        });
      }
    },
    formattingTime(second) {
      if (second === '') {
        return 0;
      }
      let h = parseInt(second / 3600, 10);
      let m = parseInt((second % 3600) / 60, 10);
      let s = parseInt((second % 3600) % 60, 10);
      if (h < 10) {
        h = `0${h}`;
      }
      if (m < 10) {
        m = `0${m}`;
      }
      if (s < 10) {
        s = `0${s}`;
      }
      if (h > 0) {
        return `${h}小时${m}分${s}秒`;
      } else if (!h && m) {
        return `${m}分${s}秒`;
      } else {
        return `${s}秒`;
      }
    },
  },
  destroyed() {
    this.amapUtils.destroy();
    if (this.riderTimeout) {
      clearTimeout(this.riderTimeout);
      this.riderTimeout = null;
    }
  },
};
</script>

<style scoped lang="scss">
.wh {
  width: 100%;
  height: 100%;
}
/deep/.el-card__body {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .map-content {
    margin-top: 6px;
    height: calc(100% - 30px);
  }
  .el-icon-back {
    font-size: 24px;
  }
  .el-page-header__title {
    font-size: 16px;
  }
}
/deep/.amap-marker-label {
  border: none !important;
  background-color: transparent !important;
}
/deep/.map-dialog {
  position: relative;
  background: #fff;
  color: #666;
  font-size: 10px;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 6px 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  .rider-type {
    font-size: 14px;
    color: #333;
  }
}
/deep/.map-dialog:before {
  position: absolute;
  top: 23px;
  left: -12px;
  content: "";
  width: 0px;
  height: 0px;
  border: 6px solid;
  border-color: transparent #fff transparent transparent;
}
/deep/.map-dialog:after {
  position: absolute;
  top: 24px;
  left: -3px;
  content: "";
  width: 10px;
  height: 10px;
  background: #fff;
  z-index: -1;
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.6);
  transform: rotate(45deg);
}
/deep/.map-marker-diy {
  width: 50px;
  height: 54px;
  background: url("/img/rider/bgImg.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 7px;
  }
}
</style>
